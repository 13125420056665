import './styles.scss';

import { SelectPartner } from '@components/modules/select-partner';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IPartner } from '@interfaces/partner';
import { IUser } from '@interfaces/user';
import {
    BaseRecord,
    useCreate,
    useGetIdentity,
    useNavigation,
    useTranslate,
} from '@refinedev/core';
import { Button, Col, Form, Input, Radio, Row, Space, Spin } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { IssuePriority } from 'enums/helpdesk.enum';
import { useEffect, useState } from 'react';

import { SA_ROLE } from '../../../../constants/index.constant';

enum CreateHelpdeskFormEnum {
    Subject = 'subject',
    Content = 'content',
    Priority = 'priority',
    Target = 'target',
    PartnerId = 'partnerId',
}

interface ICreateHelpdesk {
    subject: string;
    priority: IssuePriority;
    message: string;
    resellerId?: string;
    partnerId?: string;
    isRoot?: boolean;
}
const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

export const HelpdeskCrmCreate: React.FC = () => {
    const [form] = Form.useForm<ICreateHelpdesk>();
    const translate = useTranslate();
    const { mutate } = useCreate<BaseRecord>();
    const { goBack } = useNavigation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();
    const isReseller = userIdentity?.userType === UserTypeEnum.RESELLER;
    const onCreate = async () => {
        try {
            const isValid = await form.validateFields();

            if (isValid) {
                setIsLoading(true);
                const formValue = form.getFieldsValue();
                formValue.isRoot = false;
                mutate(
                    {
                        resource: 'v1/tickets',
                        values: formValue,
                        successNotification: { message: 'Successfully created', type: 'success' },
                        dataProviderName,
                    },
                    {
                        onError: (_) => {
                            setIsLoading(false);
                        },
                        onSuccess: (_) => {
                            setIsLoading(false);
                            goBack();
                        },
                    },
                );
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getSelectedPartner = (partner: IPartner.IPartnerInfo) => {
        form?.setFieldsValue({ resellerId: partner.id });
        form.validateFields([CreateHelpdeskFormEnum.Target]);
    };

    return (
        <section className="create-helpdesk-container w-full h-full">
            <Spin spinning={isLoading}>
                <Row className="w-full h-full">
                    <Col xs={24} className="create-helpdesk-col h-full">
                        <Row>
                            <Col span={24}>
                                <section className="helpdesk-action-container w-ful">
                                    <Row className="helpdesk-action-row w-full h-full">
                                        <Col
                                            xs={12}
                                            className="helpdesk-action-col-flex items-center"
                                        >
                                            <span className="create-helpdesk-title">
                                                {translate('helpdesk.create_issue')}
                                            </span>
                                        </Col>
                                        <Col
                                            xs={12}
                                            className="helpdesk-action-col-flex items-center justify-end"
                                        >
                                            <Button onClick={goBack}>
                                                {translate('helpdesk.cancel')}
                                            </Button>
                                            <Button
                                                className="action-button create-btn ml-4"
                                                type="primary"
                                                onClick={onCreate}
                                            >
                                                {translate('helpdesk.create')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </section>
                            </Col>
                        </Row>
                        <Form
                            form={form}
                            id="createHelpdeskForm"
                            name="create-helpdesk"
                            layout="vertical"
                            className="create-helpdesk-form w-full"
                        >
                            <Row className="create-helpdesk-form-row w-full h-full">
                                <Col xs={24} sm={16} className="create-helpdesk-form-col col-left">
                                    <Form.Item
                                        className="helpdesk-form-item form-item-subject"
                                        label={translate('helpdesk.subject')}
                                        name={CreateHelpdeskFormEnum.Subject}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.subject_required'),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        className="helpdesk-form-item form-item-message"
                                        label={translate('helpdesk.message')}
                                        name={CreateHelpdeskFormEnum.Content}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.message_required'),
                                            },
                                        ]}
                                    >
                                        <Input.TextArea />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={8}
                                    className="create-helpdesk-form-col col-right create-helpdesk-custom"
                                >
                                    <Form.Item
                                        className="helpdesk-form-item form-item-priority"
                                        label={translate('helpdesk.priority')}
                                        name={CreateHelpdeskFormEnum.Priority}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.priority_required'),
                                            },
                                        ]}
                                    >
                                        <Radio.Group value={IssuePriority.LOW}>
                                            <Space direction="vertical">
                                                {Object.values(IssuePriority).map((value) => {
                                                    return (
                                                        <Radio value={value}>
                                                            {translate(
                                                                `helpdesk.priority_enum.${value}`,
                                                            )}
                                                        </Radio>
                                                    );
                                                })}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                    {!isReseller && (
                                        <Form.Item
                                            className="helpdesk-form-item"
                                            label={translate('crm_internal.reseller')}
                                            name="resellerId"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: translate(
                                                        'crm_internal.reseller_placeholder',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <SelectPartner
                                                allowClear={false}
                                                getSelectedPartner={getSelectedPartner}
                                                dataProviderName={dataProviderName}
                                                placeholder={translate(
                                                    'crm_internal.reseller_placeholder',
                                                )}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        </section>
    );
};
